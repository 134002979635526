import styled from 'styled-components';
import { AppBar as AppBarMUI } from '@material-ui/core';

import { ReactComponent as LogoSVG } from '../images/icons/logo.svg';
import { themeColor } from '../theme/getters';
import { Color } from '../theme/theme.constants';

export const Container = styled.div`
  padding: 80px 20px 50px;
  background-color: black;
  max-width: 100vw;
  min-height: calc(100vh - 150px);
  color: ${themeColor(Color.TEXT)};
  position: relative;
`;

export const Logo = styled(LogoSVG)`
  width: 100px;
  margin-bottom: 20px;
  fill: ${themeColor(Color.TEXT)};
`;

export const AppBar = styled(AppBarMUI)`
  background-color: ${themeColor(Color.PRIMARY)};
  padding: 20px;
  display: flex;
  align-items: center;
`;
