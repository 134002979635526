import React, { ReactNode, Fragment } from 'react';

import { cond, propEq, T } from 'ramda';
import { Loader } from '../loader';
import { Error } from '../error';

export interface ContentComponentProps {
  children: ReactNode;
  loading: boolean;
  error: boolean;
  staticPosition?: boolean;
}

export const ContentComponent = ({ children, loading, error, staticPosition = false }: ContentComponentProps) =>
  cond([
    [propEq('loading', true), () => <Loader staticPosition={staticPosition} />],
    [propEq('error', true), () => <Error />],
    [T, () => <Fragment>{children}</Fragment>],
  ])({ loading, error });
