import styled, { css } from 'styled-components';

import { themeColor } from '../../../theme/getters';
import { Color } from '../../../theme/theme.constants';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  max-width: 1000px;
`;

const contentStyles = css`
  position: absolute;
  top: 0;
  left: 10px;
  transform: translateY(-50%);
  font-size: 12px;
  padding: 0 5px;
  background-color: ${themeColor(Color.BLACK)};
`;

export const BlockWrapper = styled.div`
  border: 1px solid ${themeColor(Color.BORDER)};
  padding: 30px;
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 100%;
  margin-bottom: 30px;

  &:before {
    content: '${props => props.title}';
    ${contentStyles};
  }

   &:last-child {
    margin-bottom: 0;
  }
`;

export const ElementWrapper = styled.div`
  padding: 30px;
  border: 1px dashed ${themeColor(Color.BORDER)};
  text-align: left;
  position: relative;
  max-width: 100%;
  margin-bottom: 30px;

  .observablehq {
    overflow: hidden;
  }

  &:before {
    content: '${props => props.title}';
    ${contentStyles};
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const DefaultElement = styled.div``;

export const ImageElement = styled.img`
  max-width: 100%;
`;

export const CodeElement = styled.code`
  background-color: ${themeColor(Color.BORDER)};
  padding: 20px;
  display: flex;
`;

export const ConnectionElement = styled.a`
  color: ${themeColor(Color.PINK)};
  transition: color 200ms ease-in-out;
  text-decoration: none;

  &:hover {
    color: ${themeColor(Color.DARK_PINK)};
  }
`;
