import { all, fork } from 'redux-saga/effects';

import { watchStartup } from './startup/startup.sagas';
import { watchUsers } from './users/users.sagas';
import { watchProjects } from './projects/projects.sagas';
import { watchDataSource } from './dataSource/dataSource.sagas';
import { watchSection } from './section/section.sagas';
import { watchPage } from './page/page.sagas';
import { watchPreviewMode } from './previewMode/previewMode.sagas';
//<-- IMPORT MODULE SAGA -->

export default function* rootSaga() {
  yield all([
    fork(watchStartup),
    fork(watchUsers),
    fork(watchProjects),
    fork(watchDataSource),
    fork(watchSection),
    fork(watchPage),
    fork(watchPreviewMode),
    //<-- INJECT MODULE SAGA -->
  ]);
}
