import { useDispatch } from 'react-redux';

import { changePreviewMode } from '../../../modules/previewMode/previewMode.actions';
import { useWindowListener } from '../useWindowListener';

export const usePreviewMode = () => {
  const dispatch = useDispatch();
  const SCHEMA_CMS = 'SCHEMA_CMS';
  const handleExtensionMessage = async (event: any) => {
    if (event.source !== window) {
      return;
    }
    if (event.data.type === SCHEMA_CMS) {
      await dispatch(changePreviewMode(event.data.PREVIEW_MODE));
    }
  };
  return useWindowListener('message', handleExtensionMessage, {});
};
