import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useEffectOnce } from 'react-use';
import { pipe, prop, find, propEq, T, cond, equals, any } from 'ramda';
import { MenuItem } from '@material-ui/core';

import { Container, PageContent, PageHeader } from './page.styles';
import { H1 } from '../../theme/typography';
import { useProject } from '../../shared/hooks/useProject.hook';
import { usePage } from './usePage.hook';
import { Content } from '../../shared/components/content';
import { BackLink } from '../../shared/components/backLink';
import { Select } from '../../shared/components/visualisation/visualisation.styles';
import { Page } from '../../modules/page/page.redux';
import { HtmlPage } from '../../shared/components/htmlPage';
import { StandardPage } from '../../shared/components/standardPage';

const AVAILABLE_FORMAT_OPTIONS = [
  { value: 'html', label: 'HTML' },
  { value: 'standard', label: 'Standard' },
];

const renderPageContent = (page: Page, format: string) =>
  cond([
    [equals('standard'), () => <StandardPage page={page} />],
    [equals('html'), () => <HtmlPage page={page} />],
    [T, () => null],
    // @ts-ignore
  ])(format);

export const PageComponent = () => {
  const { projectId, pageSlug } = useParams();
  const [format, setFormat] = useState(AVAILABLE_FORMAT_OPTIONS[1].value);
  const [, fetchProject] = useProject();
  const [page, fetchPage] = usePage();
  const [loading, setLoading] = useState(true);
  const [sectionSlug, setSectionSlug] = useState('');
  const [error, setError] = useState(false);
  const handleFormatChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setFormat(event.target.value as string);
  };

  useEffectOnce(() => {
    (async () => {
      try {
        const { content } = await fetchProject({ id: projectId });
        const { slug, pages } = pipe(
          // @ts-ignore
          prop('sections'),
          // @ts-ignore
          find(pipe(prop('pages'), any(propEq('slug', pageSlug))))
        )(content);
        const page = find(propEq('slug', pageSlug))(pages);

        setSectionSlug(slug);
        await fetchPage({ id: page.id });
      } catch (e) {
        setError(true);
      } finally {
        setLoading(false);
      }
    })();
  });

  return (
    <Container>
      <Content loading={loading} error={error}>
        <PageHeader>
          <H1>{page.name}</H1>
          <Select labelId="pageFormat" value={format} onChange={handleFormatChange}>
            {AVAILABLE_FORMAT_OPTIONS.map((format, index) => (
              <MenuItem key={index} value={format.value}>
                {format.label}
              </MenuItem>
            ))}
          </Select>
        </PageHeader>
        <PageContent>{renderPageContent(page, format)}</PageContent>
      </Content>
      <BackLink to={`/project/${projectId}/section/${sectionSlug}`} />
    </Container>
  );
};
