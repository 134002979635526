import styled from 'styled-components';
import { Link as LinkRRD } from 'react-router-dom';
import { Button as MUIButton } from '@material-ui/core';

import { Color } from './theme.constants';
import { themeColor } from './getters';

export const Link = styled(LinkRRD)`
  text-decoration: none;
  color: ${themeColor(Color.TEXT)};
  padding: 10px;
  cursor: pointer;
`;

export const Button = styled(MUIButton)`
  padding: 0;
`;

export const List = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
`;
