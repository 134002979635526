import { createSelector } from '@reduxjs/toolkit';
import { prop } from 'ramda';

import { PreviewModeState } from './previewMode.redux';
import { GlobalState } from '../reducers';

export const selectPreviewModeDomain = prop<string, any>('previewMode');

export const selectPreviewMode = createSelector<GlobalState, PreviewModeState, any>(
  selectPreviewModeDomain,
  prop('previewMode')
);
