import React, { ReactNode } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { FormattedMessage, IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { StylesProvider, ThemeProvider } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core';

import { ResponsiveThemeProvider } from '../shared/components/responsiveThemeProvider';
import { translationMessages } from '../i18n';
import { GlobalStyle } from '../theme/global';
import messages from './app.messages';
import { theme } from '../theme/theme';
import { localesSelectors } from '../modules/locales';
import { useStartup } from './useStartup';
import { useLanguageFromParams } from './useLanguageFromParams';
import { AppBar, Container, Logo } from './app.styles';
import { Color } from '../theme/theme.constants';

export interface AppComponentProps {
  children?: ReactNode;
}

const getMuiTheme = (colors: any) =>
  createMuiTheme({
    palette: {
      type: 'dark',
      primary: {
        main: colors[Color.PINK],
      },
    },
  });

export const AppComponent = ({ children }: AppComponentProps) => {
  useStartup();
  useLanguageFromParams();

  const language = useSelector(localesSelectors.selectLocalesLanguage);

  if (!language) {
    return null;
  }

  return (
    <IntlProvider key={language} locale={language} messages={translationMessages[language]}>
      <HelmetProvider>
        <ResponsiveThemeProvider theme={theme}>
          <ThemeProvider theme={getMuiTheme(theme.colors)}>
            <StylesProvider injectFirst>
              <Container>
                <AppBar>
                  <Link to="/">
                    <Logo />
                  </Link>
                </AppBar>
                <FormattedMessage {...messages.pageTitle}>
                  {pageTitle => <Helmet titleTemplate={`%s - ${pageTitle}`} defaultTitle={pageTitle} />}
                </FormattedMessage>

                <GlobalStyle />
                {React.Children.only(children)}
              </Container>
            </StylesProvider>
          </ThemeProvider>
        </ResponsiveThemeProvider>
      </HelmetProvider>
    </IntlProvider>
  );
};
