import styled from 'styled-components';
import { CircularProgress as CircularProgressMUI } from '@material-ui/core';

import { Color } from '../../../theme/theme.constants';
import { themeColor } from '../../../theme/getters';
import { LoaderComponentProps } from './loader.component';

export const Container = styled.div`
  display: flex;
  min-width: 100%;
  justify-content: center;
  align-items: center;
  position: ${({ staticPosition }: LoaderComponentProps) => (staticPosition ? 'static' : 'fixed')};
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
`;

export const CircularProgress = styled(CircularProgressMUI)`
  color: ${themeColor(Color.PINK)};
`;
