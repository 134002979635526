import { all, takeLatest, call } from 'redux-saga/effects';
// @ts-ignore
import { resolvePromiseAction, rejectPromiseAction } from '@adobe/redux-saga-promise';

import reportError from '../../shared/utils/reportError';
import { projectsActions } from '.';
import api from '../../shared/services/api';

export const PROJECTS_URL = '/projects';

function* fetchProjects(action: any) {
  try {
    const { data } = yield api.get(`${PROJECTS_URL}`);
    yield call(resolvePromiseAction, action, data.results);
  } catch (error) {
    reportError(error);
    yield call(rejectPromiseAction, action);
  }
}

function* fetchProject(action: any) {
  const {
    payload: { id },
  } = action;
  try {
    const { data } = yield api.get(`${PROJECTS_URL}/${id}`);

    yield call(resolvePromiseAction, action, data);
  } catch (error) {
    reportError(error);
    yield call(rejectPromiseAction, action);
  }
}

export function* watchProjects() {
  yield all([
    takeLatest(projectsActions.fetchProject, fetchProject),
    takeLatest(projectsActions.fetchProjects, fetchProjects),
  ]);
}
