import { createReducer } from '@reduxjs/toolkit';
import { ReduxAction, actionHandler } from '../helpers';
import * as dataSourceActions from './dataSource.actions';

export interface Meta {
  name: string;
}

export interface Field {
  name: string;
  type: string;
}

export interface DataSource {
  meta: Meta;
  records: any[];
  fields: Field[];
}

export interface DataSourceState {
  dataSource: DataSource | object;
}

export const INITIAL_STATE: DataSourceState = {
  dataSource: {
    meta: {
      name: '',
    },
    fields: [],
    records: [],
  },
};

const handleFetchDataSourceResolved = (state: DataSourceState, { payload }: ReduxAction<any>) => {
  state.dataSource = payload;
};

const HANDLERS = {
  ...actionHandler(dataSourceActions.fetchDataSource.resolved, handleFetchDataSourceResolved),
};

export const reducer = createReducer(INITIAL_STATE, HANDLERS);
