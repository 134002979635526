import { all, put, takeLatest, select } from 'redux-saga/effects';

import reportError from '../../shared/utils/reportError';
import { previewModeActions } from '.';
import { selectPage } from '../page/page.selectors';
import { pageActions } from '../page';

function* changePreviewMode() {
  try {
    const { id } = yield select(selectPage);
    yield put(pageActions.fetchPage({ id }));
  } catch (error) {
    reportError(error);
  }
}

export function* watchPreviewMode() {
  yield all([takeLatest(previewModeActions.changePreviewMode, changePreviewMode)]);
}
