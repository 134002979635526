import React from 'react';
import { cond, equals, T } from 'ramda';

import {
  Container,
  BlockWrapper,
  ElementWrapper,
  DefaultElement,
  ImageElement,
  CodeElement,
  ConnectionElement,
} from './standardPage.styles';
import { Block, Page, Element } from '../../../modules/page/page.redux';
import { ObservableHQElement } from './obervableHQElement.component';
import { CustomElement } from './customElement.component';

export interface StandardPageComponentProps {
  page: Page;
}

const imageElement = (element: Element) => <ImageElement src={element.value.image} alt={element.value.fileName} />;
const connectionElement = (element: Element) => (
  <ConnectionElement href={element.value}>{element.value}</ConnectionElement>
);
const codeElement = (element: Element) => <CodeElement>{element.value}</CodeElement>;
const markdownElement = (value: string) => <DefaultElement dangerouslySetInnerHTML={{ __html: value }} />;
const defaultElement = (value: string) => <DefaultElement>{value}</DefaultElement>;

export const renderElement = (element: Element) =>
  cond([
    [equals('image'), () => imageElement(element)],
    [equals('connection'), () => connectionElement(element)],
    [equals('internal_connection'), () => connectionElement(element)],
    [equals('code'), () => codeElement(element)],
    [equals('markdown'), () => markdownElement(element.html)],
    [equals('custom_element'), () => <CustomElement value={element.value} />],
    [equals('observable_hq'), () => <ObservableHQElement {...element.value} />],
    [T, () => defaultElement(element.value)],
    // @ts-ignore
  ])(element.type);

export const renderBlock = (block: Block, index: number) => (
  <BlockWrapper key={index} title={block.name}>
    {block.elements.map((element, index) => (
      <ElementWrapper key={index} title={`${element.name} - ${element.type} type`}>
        {renderElement(element)}
      </ElementWrapper>
    ))}
  </BlockWrapper>
);

export const StandardPageComponent = ({ page }: StandardPageComponentProps) => (
  <Container>{page.blocks.map(renderBlock)}</Container>
);
