import { createGlobalStyle } from 'styled-components';
import { Color, Font } from './theme.constants';
import { themeFont, themeColor } from './getters';

export const GlobalStyle = createGlobalStyle`
  html {
    box-sizing: border-box;
    font-family: ${themeFont(Font.PRIMARY)};
    background-color: ${themeColor(Color.PRIMARY)};
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }
`;
