import styled from 'styled-components';

import { themeColor } from '../../../theme/getters';
import { Color } from '../../../theme/theme.constants';

export const Iframe = styled.iframe`
  background-color: ${themeColor(Color.WHITE)};
  border: none;
  min-width: 100vw;
  min-height: calc(100vh - 250px);
`;
