import React, { useState } from 'react';
import { useEffectOnce } from 'react-use';
import { useParams } from 'react-router-dom';
import { find, pipe, prop, propEq } from 'ramda';

import { Container } from './section.styles';
import { H1 } from '../../theme/typography';
import { List, Button, Link } from '../../theme/components';
import { renderWhenTrueOtherwise } from '../../shared/utils/rendering';
import { Page } from '../../modules/section/section.redux';
import { Content } from '../../shared/components/content';
import { BackLink } from '../../shared/components/backLink';
import { useProject } from '../../shared/hooks/useProject.hook';
import { SECTION_INITIAL_STATE } from '../../modules/section';

const renderList = (pages: Page[], projectId: string) =>
  renderWhenTrueOtherwise(
    () => 'No Pages',
    () => (
      <List>
        {pages.map(({ id, name, slug }, index) => (
          <Button key={index}>
            <Link to={`/project/${projectId}/page/${slug}`}>{name}</Link>
          </Button>
        ))}
      </List>
    )
  )(!pages.length);

export const SectionComponent = () => {
  const { sectionSlug, projectId } = useParams();
  const [section, setSection] = useState(SECTION_INITIAL_STATE.section);
  const [, fetchProject] = useProject();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffectOnce(() => {
    (async () => {
      try {
        const { content } = await fetchProject({ id: projectId });
        // @ts-ignore
        const section = pipe(prop('sections'), find(propEq('slug', sectionSlug)))(content);
        setSection(section);
      } catch (e) {
        setError(true);
      } finally {
        setLoading(false);
      }
    })();
  });

  return (
    <Container>
      <Content loading={loading} error={error}>
        <H1>{section.name}</H1>
        {renderList(section.pages, projectId)}
      </Content>
      <BackLink to="/" />
    </Container>
  );
};
