import { createSelector } from '@reduxjs/toolkit';
import { prop } from 'ramda';

import { ProjectsState } from './projects.redux';
import { GlobalState } from '../reducers';

export const selectProjectsDomain = prop<string, any>('projects');

export const selectProject = createSelector<GlobalState, ProjectsState, any>(selectProjectsDomain, prop('project'));
export const selectProjects = createSelector<GlobalState, ProjectsState, any>(selectProjectsDomain, prop('projects'));
