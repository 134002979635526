import React from 'react';

import { Container } from './error.styles';
import { H1 } from '../../../theme/typography';

export const ErrorComponent = () => {
  return (
    <Container>
      <H1>Something went wrong. Please try again later.</H1>
    </Container>
  );
};
