import { createReducer } from '@reduxjs/toolkit';
import { ReduxAction, actionHandler } from '../helpers';
import * as pageActions from './page.actions';

export type Element = {
  name: string;
  type: string;
  html: string;
  value: any;
};

export interface Block {
  name: string;
  elements: Element[];
}

export interface Page {
  name: string;
  id?: number;
  blocks: Block[];
}

export interface PageState {
  page: Page;
}

export const INITIAL_STATE: PageState = {
  page: {
    name: '',
    blocks: [],
  },
};

const handleFetchPageResolved = (state: PageState, { payload }: ReduxAction<any>) => {
  state.page = payload;
};

const HANDLERS = {
  ...actionHandler(pageActions.fetchPage.resolved, handleFetchPageResolved),
};

export const reducer = createReducer(INITIAL_STATE, HANDLERS);
