import { useDispatch, useSelector } from 'react-redux';
import { projectsActions, projectsSelectors } from '../../modules/projects';
import { Project } from '../../modules/projects/projects.redux';

interface FetchPayload {
  id: string;
}

export const useProject = (): [Project, (payload: FetchPayload) => Project] => {
  const dispatch = useDispatch();

  const project = useSelector(projectsSelectors.selectProject);
  const fetchProject = (payload: FetchPayload) => dispatch(projectsActions.fetchProject(payload));

  return [project, fetchProject];
};
