import React from 'react';

import { Iframe } from './htmlPage.styles';
import { Page } from '../../../modules/page/page.redux';

export interface HtmlPageComponentProps {
  page: Page;
}

export const HtmlPageComponent = ({ page }: HtmlPageComponentProps) => {
  return <Iframe src={`${process.env.REACT_APP_BASE_API_URL}/pages/${page.id}/html`} />;
};
