import { createReducer } from '@reduxjs/toolkit';
import { ReduxAction, actionHandler } from '../helpers';
import * as sectionActions from './section.actions';

export interface Page {
  name: string;
  slug: string;
  id?: number;
}

export interface Section {
  name: string;
  slug: string;
  pages: Page[];
  id?: number;
}

export interface SectionState {
  section: Section;
}

export const INITIAL_STATE: SectionState = {
  section: {
    name: '',
    slug: '',
    pages: [],
  },
};

const handleFetchSectionResolved = (state: SectionState, { payload }: ReduxAction<any>) => {
  state.section = payload;
};

const HANDLERS = {
  ...actionHandler(sectionActions.fetchSection.resolved, handleFetchSectionResolved),
};

export const reducer = createReducer(INITIAL_STATE, HANDLERS);
