import { createReducer } from '@reduxjs/toolkit';
import { ReduxAction, actionHandler } from '../helpers';
import { previewModeActions } from '.';

export interface PreviewModeState {
  previewMode: boolean;
  data: any;
}

export const INITIAL_STATE: PreviewModeState = {
  previewMode: false,
  data: [],
};

const handleChangePreviewMode = (state: PreviewModeState, { payload }: ReduxAction<any>) => {
  state.previewMode = payload;
};

const handleFetchPreviewModeSuccess = (state: PreviewModeState, { payload }: ReduxAction<any>) => {
  state.data = payload;
};

const HANDLERS = {
  ...actionHandler(previewModeActions.changePreviewMode, handleChangePreviewMode),
  ...actionHandler(previewModeActions.changePreviewModeSuccess, handleFetchPreviewModeSuccess),
};

export const reducer = createReducer(INITIAL_STATE, HANDLERS);
