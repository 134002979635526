import styled from 'styled-components';
import { Select as SelectMUI } from '@material-ui/core';

export const Container = styled.div``;

export const Select = styled(SelectMUI)`
  min-width: 120px;
`;

export const FormsContainer = styled.div`
  max-width: 500px;
  margin: 0 auto;
  width: 100%;
`;

export const ChartContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;
