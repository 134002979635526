import { createReducer } from '@reduxjs/toolkit';
import { actionHandler, ReduxAction } from '../helpers';
import * as projectsActions from './projects.actions';

export interface DataSource {
  id: number;
  name: string;
}

export interface Meta {
  title: string;
  id?: number;
}

export interface Section {
  name: string;
  slug: string;
  id?: number;
}

export interface Content {
  sections: Section[];
}

export interface Project {
  meta: Meta;
  dataSources: DataSource[];
  content: Content;
}

export interface ProjectsState {
  project: Project;
  projects: Project[];
}

export const INITIAL_STATE: ProjectsState = {
  project: {
    meta: {
      title: '',
    },
    dataSources: [],
    content: {
      sections: [],
    },
  },
  projects: [],
};

const handleFetchProjectResolved = (state: ProjectsState, { payload }: ReduxAction<any>) => {
  state.project = payload;
};

const handleFetchProjectsResolved = (state: ProjectsState, { payload }: ReduxAction<any>) => {
  state.projects = payload;
};

const HANDLERS = {
  ...actionHandler(projectsActions.fetchProject.resolved, handleFetchProjectResolved),
  ...actionHandler(projectsActions.fetchProjects.resolved, handleFetchProjectsResolved),
};

export const reducer = createReducer(INITIAL_STATE, HANDLERS);
