import axios from 'axios';
// @ts-ignore
import { camelizeKeys } from 'humps';
import { evolve } from 'ramda';

let isInPreviewMode = false;

window.addEventListener(
  'message',
  event => {
    if (event.source !== window) {
      return;
    }
    if (event.data.type === 'SCHEMA_CMS') {
      isInPreviewMode = event.data.PREVIEW_MODE;
    }
  },
  false
);

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
  params: { camelize: true },
});

api.interceptors.request.use(
  async config => {
    if (isInPreviewMode && config.url.includes('pages')) {
      config.url = `${config.baseURL}${config.url}/draft`;
      return config;
    }
    config.baseURL = process.env.REACT_APP_BASE_API_URL;
    return config;
  },
  (error: any) => Promise.reject(error)
);

api.interceptors.response.use(
  response => {
    if (response.config.params.camelize) {
      return evolve({
        data: camelizeKeys,
      })(response);
    }
    return response;
  },
  error => {
    return Promise.reject(error);
  }
);

export default api;
