import { useDispatch, useSelector } from 'react-redux';
import { pageActions, pageSelectors } from '../../modules/page';
// @ts-ignore
import { Page } from '../../modules/page/page.redux';

interface FetchPayload {
  id: any;
}

export const usePage = (): [Page, (payload: FetchPayload) => void] => {
  const dispatch = useDispatch();

  const page = useSelector(pageSelectors.selectPage);
  const fetchPage = (payload: FetchPayload) => dispatch(pageActions.fetchPage(payload));

  return [page, fetchPage];
};
