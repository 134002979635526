import React from 'react';

import { Container } from './backLink.styles';
import { Link, Button } from '../../../theme/components';

export interface BackLinkComponentProps {
  to: string;
}

export const BackLinkComponent = ({ to }: BackLinkComponentProps) => {
  return (
    <Container>
      <Button>
        <Link to={to}>Back</Link>
      </Button>
    </Container>
  );
};
