import { combineReducers } from 'redux';

import { reducer as localesReducer, LocalesState } from './locales/locales.redux';
import { reducer as startupReducer, StartupState } from './startup/startup.redux';
import { reducer as usersReducer, UsersState } from './users/users.redux';
import { reducer as projectsReducer, ProjectsState } from './projects/projects.redux';
import { reducer as dataSourceReducer, DataSourceState } from './dataSource/dataSource.redux';
import { reducer as sectionReducer, SectionState } from './section/section.redux';
import { reducer as pageReducer, PageState } from './page/page.redux';
import { reducer as previewModeReducer, PreviewModeState } from './previewMode/previewMode.redux';
//<-- IMPORT MODULE REDUX -->

export type GlobalState = {
  locales: LocalesState;
  startup: StartupState;
  users: UsersState;
  projects: ProjectsState;
  dataSource: DataSourceState;
  section: SectionState;
  page: PageState;
  previewMode: PreviewModeState;
  //<-- INJECT MODULE STATE TYPE -->
};
const appReducer = combineReducers({
  locales: localesReducer,
  startup: startupReducer,
  users: usersReducer,
  projects: projectsReducer,
  dataSource: dataSourceReducer,
  section: sectionReducer,
  page: pageReducer,
  previewMode: previewModeReducer,
  //<-- INJECT MODULE REDUCER -->
});

const rootReducer = (state: any, action: any) => {
  if (action.type === 'PREVIEW_MODE/CHANGE_PREVIEW_MODE') {
    // state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
