import { all, call, takeLatest } from 'redux-saga/effects';
import { values } from 'ramda';
// @ts-ignore
import { resolvePromiseAction, rejectPromiseAction } from '@adobe/redux-saga-promise';

import reportError from '../../shared/utils/reportError';
import { dataSourceActions } from '.';
import api from '../../shared/services/api';

export const DATA_SOURCE_URL = '/datasources';

function* fetchDataSource(action: any) {
  const {
    payload: { id },
  } = action;

  try {
    const { data } = yield api.get(`${DATA_SOURCE_URL}/${id}`);
    data.fields = values(data.fields);
    const {
      data: { results },
    } = yield api.get(`${DATA_SOURCE_URL}/${id}/records?orient=records&page_size=5000`, {
      params: { camelize: false },
    });
    data.records = results;

    yield call(resolvePromiseAction, action, data);
  } catch (error) {
    reportError(error);
    yield call(rejectPromiseAction, action);
  }
}

export function* watchDataSource() {
  yield all([takeLatest(dataSourceActions.fetchDataSource, fetchDataSource)]);
}
