import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import { hot } from 'react-hot-loader/root';

import { AppComponent as App } from './app.component';
import { DEFAULT_LOCALE, translationMessages } from '../i18n';
import { ROUTES } from './app.constants';
import { asyncComponent } from '../shared/utils/asyncComponent';
import { Section } from './section';
import { Page } from './page';
import { usePreviewMode } from '../shared/hooks/usePreviewMode';
//<-- IMPORT ROUTE -->

const Home = asyncComponent(() => import('./home'), 'Home');
const NotFound = asyncComponent(() => import('./notFound'), 'NotFound');
const DataSource = asyncComponent(() => import('./dataSource'), 'DataSource');

const MatchedLanguageComponent = () => {
  usePreviewMode();

  const match = useRouteMatch();

  return (
    <App>
      <Switch>
        <Route exact path={`${match.path}${ROUTES.home}`}>
          <Home />
        </Route>
        <Route exact path={`${match.path}${ROUTES.dataSource}/:dataSourceId`}>
          <DataSource />
        </Route>
        <Route exact path={`${match.path}${ROUTES.project}/:projectId/${ROUTES.section}/:sectionSlug/`}>
          <Section />
        </Route>
        <Route exact path={`${match.path}${ROUTES.project}/:projectId/${ROUTES.page}/:pageSlug`}>
          <Page />
        </Route>
        <Route component={NotFound} />
      </Switch>
    </App>
  );
};

export default hot(() => {
  return (
    <Switch>
      <Route path="/">
        <MatchedLanguageComponent />
      </Route>

      <IntlProvider locale={DEFAULT_LOCALE} messages={translationMessages[DEFAULT_LOCALE]}>
        <Route>
          <NotFound />
        </Route>
      </IntlProvider>
    </Switch>
  );
});
