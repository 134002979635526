import { all, call, takeLatest } from 'redux-saga/effects';
// @ts-ignore
import { resolvePromiseAction, rejectPromiseAction } from '@adobe/redux-saga-promise';

import reportError from '../../shared/utils/reportError';
import { sectionActions } from '.';
import api from '../../shared/services/api';

function* fetchSection(action: any) {
  const {
    payload: { id },
  } = action;

  try {
    const { data } = yield api.get(`/sections/${id}`);

    yield call(resolvePromiseAction, action, data);
  } catch (error) {
    reportError(error);
    yield call(rejectPromiseAction, action);
  }
}

export function* watchSection() {
  yield all([takeLatest(sectionActions.fetchSection, fetchSection)]);
}
