import React from 'react';

import { Container, CircularProgress } from './loader.styles';

export interface LoaderComponentProps {
  staticPosition: boolean;
}

export const LoaderComponent = ({ staticPosition }: LoaderComponentProps) => {
  return (
    <Container staticPosition={staticPosition}>
      <CircularProgress />
    </Container>
  );
};
