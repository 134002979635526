import { createSelector } from '@reduxjs/toolkit';
import { prop } from 'ramda';

import { DataSourceState } from './dataSource.redux';
import { GlobalState } from '../reducers';

export const selectDataSourceDomain = prop<string, any>('dataSource');

export const selectDataSource = createSelector<GlobalState, DataSourceState, any>(
  selectDataSourceDomain,
  prop('dataSource')
);
