import React, { useRef, useState } from 'react';
import { useEffectOnce } from 'react-use';
// @ts-ignore
import { importModule } from '@uupaa/dynamic-import-polyfill';
// @ts-ignore
import { Runtime, Inspector } from '@observablehq/runtime';
import { Content } from '../content';

export interface ObservableHQElementComponentProps {
  observableUser: any;
  observableNotebook: any;
  observableCell: any;
  observableParams: any;
}

export const ObservableHQElement = ({
  observableUser,
  observableNotebook,
  observableParams,
  observableCell,
}: ObservableHQElementComponentProps) => {
  const animationRef = useRef(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffectOnce(() => {
    (async () => {
      try {
        const notebook = await importModule(
          `https://api.observablehq.com/${observableUser}/${observableNotebook}.js${observableParams}`
        );
        const runtime = new Runtime();
        runtime.module(notebook.default, (name: string) => {
          if (name === observableCell) {
            setLoading(false);
            return new Inspector(animationRef.current);
          }

          return null;
        });
      } catch (e) {
        setError(true);
      }
    })();
  });
  return (
    <Content loading={loading} error={error} staticPosition>
      <div ref={animationRef} />
    </Content>
  );
};
